
export default class MyCalculation {
  constructor() {
    //super(props);
    console.log("MyCalculation: constructor"); 
  }

  static calculateSavedByScreeningFromRisk(risk) {
    return Math.round(Math.round(risk * 10) - Math.round(risk * 10 * 0.8));
  }

  static calculatePackYears(smokeYears, numCigarettes) {
    return  smokeYears * numCigarettes / 20;
  }
}


