class Variable {
  static pack_id = "variable default";
  static risk_id = "varibale default";
  static risk_confirmation_id = "varibale default";
  static benefit_confirmation_id = "varibale default";
  static reflection_id = "variable default";
  static feedback_id = "variable default";
  static language = "English";
  static session_id = "variable default";

 
  constructor() {
    console.log("Variable: constructor");
  }

  static setValue(name, value) {
    switch (name) {
      case "pack_id":
        Variable.pack_id = value;
        break;
      case "risk_id":
        Variable.risk_id = value;
        break;
      case "risk_confirmation_id":
        Variable.risk_confirmation_id = value;
        break;
      case "benefit_confirmation_id":
        Variable.benefit_confirmation_id = value;
        break;
      case "reflection_id":
        Variable.reflection_id = value;
        break;
      case "feedback_id":
        Variable.feedback_id = value;
        break;
      case "session_id":
        Variable.session_id = value;
        break;

      case "language":
        //Variable.language = value;
        localStorage.setItem("language", value);
        alert("After set language: " + localStorage.getItem("language") );
        break;
      default:
        break;
    }
    console.log("Set " + name + "=" + value);
    
  }

  static getValue(name) {
    switch (name) {
      case "pack_id":
        console.log("Get " + name + ":" + Variable.pack_id);
        return Variable.pack_id;
      case "risk_id":
        console.log("Get " + name + ":" + Variable.risk_id);
        return Variable.risk_id;
      case "risk_confirmation_id":
        console.log("Get " + name + ":" + Variable.risk_confirmation_id);
        return Variable.risk_confirmation_id;
      case "benefit_confirmation_id":
        console.log("Get " + name + ":" + Variable.benefit_confirmation_id);
        return Variable.benefit_confirmation_id;
      case "reflection_id":
        console.log("Get " + name + ":" + Variable.reflection_id);
        return Variable.reflection_id;
      case "feedback_id":
        console.log("Get " + name + ":" + Variable.feedback_id);
        return Variable.feedback_id;
      case "session_id":
        console.log("Get " + name + ":" + Variable.session_id);
        return Variable.session_id;
      /*
      case "route_order_list":
        console.log("Get " + name + ":" + Variable.routeOrderedList);
        return Variable.routeOrderedList;
      */
      case "language":
        console.log("Get " + name + ":" + Variable.language);
        //alert("Get " + name + ":" + Variable.language);

        if( localStorage.getItem("language") == undefined){
          localStorage.setItem("language", "English");
        }
        return localStorage.getItem("language");
      default:
        console.log("Get " + name + ": default");
        return "default value";
    }
  }
}

export default Variable;
